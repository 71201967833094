<template>
    <a-row class="main">
      <a-col :lg="{ span: 6 }" :xxl="{ span: 4 }" class="left-sty">
        <!--监测点-->
        <el-card class="card-left-sty">
          <div style="height: 0.8rem">
            监测点
            <span style="margin-left: 1rem"></span>
          </div>
          <br />
          <div style="margin-bottom: 12px"></div>
          <template>
            <div>
              <a-input-search
                style="margin-bottom: 8px"
                placeholder="搜索监测点"
                @change="onChange"
              >
              </a-input-search>
              <a-tree
                :replaceFields="tree_replaceFields"
                :style="{
                  overflow: 'auto',
                }"
                class="scroll_bar"
                @expand="onExpand"
                @select="onSelect"
                show-icon
                :expanded-keys="expandedKeys"
                :auto-expand-parent="autoExpandParent"
                :tree-data="measurePointData"
              >
                <a-icon slot="switcherIcon" type="down" />
                <a-icon
                  slot="organization"
                  type="home"
                  theme="twoTone"
                  two-tone-color="#5AA8FF"
                />
                <a-icon
                  slot="farm"
                  type="home"
                  theme="twoTone"
                  two-tone-color="#52c41a"
                />
                <a-icon
                  slot="measurePoint"
                  type="api"
                  theme="twoTone"
                  two-tone-color="#FFA02B"
                />

                <template slot="title" slot-scope="{ name }">
                  <span v-if="name.indexOf(searchValue) > -1">
                    {{ name.substr(0, name.indexOf(searchValue)) }}
                    <span style="color: #f50">{{ searchValue }}</span>
                    {{
                      name.substr(
                        name.indexOf(searchValue) + searchValue.length
                      )
                    }}
                  </span>
                  <span v-else>{{ name }}</span>
                </template>
              </a-tree>
            </div>
          </template>
        </el-card>
      </a-col>
      <a-col :lg="{ span: 18 }" :xxl="{ span: 20 }" class="left-sty">
        <div style="width: 100%">
          <el-card style="margin: 15px">
            <a-row>
              <a-col :span="12">
                <div style="font-weight: 700">监测参数:</div>
                <div style="margin-top: 1rem">
                  <a-tag
                    color="#87d068"
                    v-for="item in onlineMonitoringDataCollect"
                    :key="item.id"
                  >
                    {{ item.describe }}
                  </a-tag>
                </div>
              </a-col>
              <a-col :span="12">
                <div style="font-weight: 700">选择时间范围:</div>
                <div style="margin-top: 1rem">
                  <a-range-picker
                    v-model="query.time"
                    @change="getOnlineMonitoringData"
                  />
                </div>
              </a-col>
            </a-row>
          </el-card>
          <!--监测图  -->
          <el-card style="margin: 15px">
            <div id="cylindricality"></div>
          </el-card>

          <!--实时数据和预警数据-->
          <el-card style="margin: 15px">
            <a-tabs default-active-key="1" style="margin-top: -10px">
              <a-tab-pane key="1" tab="实时数据">
                <el-table
        header-row-class-name="my-table-head-style"
                  style="margin-top: 0; margin-bottom: 0"
                  v-loading="spinning"
                  :data="onlineMonitoringDataLatestValue"
                  row-key="id"
                >
                  <el-table-column
                    prop="describe"
                    label="监测参数"
                  ></el-table-column>

                  <el-table-column prop="value" label="最新值">
                    <template slot-scope="scope">
                      <span>{{ scope.row.value }}</span
                      >&nbsp;
                      <span>{{ scope.row.unit }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="referenceValue" label="状态">
                    <template slot-scope="scope">
                      <a-badge
                        :status="
                          scope.row.state === 'NORMAL'
                            ? 'success'
                            : scope.row.state === 'ABNORMAL'
                            ? 'warning'
                            : 'error'
                        "
                      />
                      {{ scope.row.state | StateFiltr }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="latestTime"
                    label="时间"
                  ></el-table-column>
                </el-table>
              </a-tab-pane>
              <a-tab-pane key="2" tab="预警数据" force-render>
                <el-table
        header-row-class-name="my-table-head-style"
                  style="margin-top: 0; margin-bottom: 0"
                  v-loading="spinning"
                  :data="onlineMonitoringDataWarningHistoryValue"
                  row-key="id"
                >
                  <el-table-column
                    prop="meterCollectItemDescribe"
                    label="监测参数"
                  ></el-table-column>

                  <el-table-column
                    prop="abnormalDescribe"
                    label="预警数据"
                  ></el-table-column>

                  <el-table-column prop="abnormalValue" label="异常值">
                    <template slot-scope="scope">
                      <span style="color: #ff061d">
                        {{ scope.row.abnormalValue }}
                        {{ scope.row.meterCollectItemUnit }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="referenceValue" label="参考值">
                    <template slot-scope="scope">
                      <span
                        >{{ scope.row.referenceValue }}
                        {{ scope.row.meterCollectItemUnit }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="time" label="时间">
                    <template slot-scope="scope">
                      {{ scope.row.time | dateFormatFull }}
                    </template>
                  </el-table-column>
                </el-table>
                <div class="block">
                  <el-pagination
                    layout="prev, pager, next"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    @current-change="handleCurrentChange"
                    :total="totalCount"
                  >
                  </el-pagination>
                </div>
              </a-tab-pane>
            </a-tabs>
          </el-card>
        </div>
      </a-col>
    </a-row>
</template>

<script>
import DataSet from "@antv/data-set";
import { Chart } from "@antv/g2";

export default {
  data() {
    return {
      windDirectionMeterIds: [],
      currentPage: 1,
      pageSize: 5,
      totalCount: 0,
      charObj: [],
      tree_replaceFields: { children: "children", title: "name", key: "code" },
      query: {
        time: [],
        measurePointId: "",
      },
      spinning: true,
      screenWidth: document.documentElement.clientWidth, //屏幕宽度
      screenHeight: document.documentElement.clientHeight - 50, //屏幕高度
      titleData: [], //标题所有title的数据
      expandedKeys: [], //树key
      searchValue: "", //树搜索
      autoExpandParent: true, //树
      searchText: "", //搜索
      searchInput: null, //搜索
      treeSelectedKeys: [],
      measurePointData: [],
      onlineMonitoringDataCollect: [],
      onlineMonitoringDataLatestValue: [],
      onlineMonitoringDataHistoryValue: [],
      onlineMonitoringDataWarningHistoryValue: [],
      onlineMonitoringDataHistoryValue1: [],
    };
  },
  watch: {
    //监听屏幕宽度变化
    screenWidth: function (val) {
      console.log(val);
    },
    //监听屏幕高度变化
    screenHeight: function (val) {},
    //在线监测数据历史值
    onlineMonitoringDataHistoryValue: function (val) {
      if (this.windDirectionMeterIds.indexOf(this.measurePointMsg.id) > -1) {
        this.charObj.destroy();
        this.$nextTick(() => {
          this.windDirectionCharInit();
          this.charObj.data(val);
          this.charObj.render();
        });
      } else {
        this.charObj.destroy();
        this.$nextTick(() => {
          this.charInit();
          this.charObj.data(val);
          this.charObj.render();
        });
      }
    },
    //树形选择
    treeSelectedKeys: function (val) {
      this.getOnlineMonitoringData();
    },
  },

  created() {
    this.getWindDirectionMeterIds();
    this.getMeasurePointData();
    this.getOnlineMonitoringData();
  },

  mounted() {
    this.charInit();
    // 定义窗口大小变更通知事件
    window.onresize = () => {
      this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
    };
  },
  filters: {
    StateFiltr: function (value) {
      if (!value) return "";
      if (value === "NORMAL") {
        return "正常";
      } else if (value === "ABNORMAL") {
        return "异常";
      } else if (value === "OFFLINE") {
        return "离线";
      } else {
        return "离线";
      }
    },
  },
  computed: {},
  methods: {
    getWindDirectionMeterIds() {
      this.http.device.getWindDirectionMeterIds().then((res) => {
        if (res.data.status === 200) {
          this.windDirectionMeterIds = res.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    //获取测量点数据
    getMeasurePointData() {
      this.http.monitor.getOrganizationMeasurePointTree({}).then((res) => {
        if (res.data.status === 200) {
          let list = res.data.data;
          list.forEach((val) => {
            val.selectable = false;
            if (val.children) {
              val.children.forEach((vall) => {
                vall.selectable = false;
              });
            }
          });
          this.measurePointData = list;
          this.getTitleData(list);
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },

    //获取在线监控数据
    getOnlineMonitoringData() {
      let that = this;
      let params = {
        measurePointId: that.treeSelectedKeys[0],
        startTime:
          that.query.time.length > 0
            ? that.query.time[0].format("YYYY-MM-DD")
            : "",
        endTime:
          that.query.time.length > 0
            ? that.query.time[1].format("YYYY-MM-DD")
            : "",
      };
      that.http.monitor
        .getOnlineMonitoringData(params)
        .then((res) => {
          if (res.data.status === 200) {
            that.spinning = false;
            if (res.data.data != null) {
              that.onlineMonitoringDataCollect = res.data.data.collect;
              that.onlineMonitoringDataLatestValue = res.data.data.latestValue;
              that.onlineMonitoringDataHistoryValue =
                res.data.data.historyValue;
              that.measurePointMsg = res.data.data.measurePoint[0];
            }
          } else {
            that.$message.warning("数据获取失败");
          }
        })
        .then(() => {
          that.getOnlineMonitoringWarningData();
        });
    },

    //获取在线监控报警数据
    getOnlineMonitoringWarningData() {
      let that = this;
      let params = {
        size: this.pageSize,
        current: this.currentPage,
        measurePointId: this.treeSelectedKeys[0],
        startTime:
          this.query.time.length > 0
            ? this.query.time[0].format("YYYY-MM-DD")
            : "",
        endTime:
          this.query.time.length > 0
            ? this.query.time[1].format("YYYY-MM-DD")
            : "",
      };
      that.http.monitor.getOnlineMonitoringWarningData(params).then((res) => {
        if (res.data.status === 200) {
          if (res.data.data != null) {
            this.onlineMonitoringDataWarningHistoryValue =
              res.data.data.records;
            this.totalCount = res.data.data.total;
            this.currentPage = res.data.data.current;
          }
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.getOnlineMonitoringWarningData();
    },
    //初始化图表
    charInit() {
      this.charObj = new Chart({
        container: "cylindricality",
        autoFit: true,
        height: 300,
      });
      this.charObj.scale({
        collectItem: {
          range: [0, 1],
        },
        value: {
          alias: "值",
          nice: true,
        },
      });

      this.charObj.tooltip({
        showCrosshairs: true,
        shared: true,
      });

      this.charObj.axis("value", {});
      this.charObj
        .area()
        .adjust("stack")
        .position("time*value")
        .color("collectItem");
      this.charObj
        .line()
        .adjust("stack")
        .position("time*value")
        .color("collectItem");
      this.charObj.interaction("element-highlight");

      // 复写 图例筛选 交互。
      // 1、点击图例名称 进行 unchecked 状态的切换
      // 2、点击图例 marker，进行 checked 状态的切换（进行聚焦）
      // 3、双击 重置状态
      this.charObj.interaction("legend-filter", {
        start: [
          {
            trigger: "legend-item-name:click",
            action: ["list-unchecked:toggle", "data-filter:filter"],
          },
          {
            trigger: "legend-item-marker:click",
            action: ["list-checked:checked", "data-filter:filter"],
          },
        ],
        end: [
          {
            trigger: "legend-item-marker:dblclick",
            action: ["list-checked:reset", "data-filter:filter"],
          },
        ],
      });
    },
    //风向图
    windDirectionCharInit() {
      const colors = [
        "#E3F4BF",
        "#BEF7C8",
        "#86E6C8",
        "#36CFC9",
        "#209BDD",
        "#1581E6",
        "#0860BF",
      ];
      this.charObj = new Chart({
        container: "cylindricality",
        autoFit: true,
        height: 300,
      });
      this.charObj.coordinate("polar", {
        radius: 0.85,
      });
      this.charObj.tooltip({
        showMarkers: false,
        shared: true,
      });
      this.charObj.axis("value", {
        label: {
          offset: 0,
          style: {
            textAlign: "center",
          },
        },
        tickLine: null,
        line: {
          style: {
            stroke: "#E9E9E9",
            lineDash: [3, 3],
          },
        },
        grid: {
          line: {
            style: {
              lineDash: [3, 3],
            },
          },
        }, // 设置坐标系栅格样式
      });
      this.charObj
        .interval()
        .position("collectItem*value")
        .color("collectItem", colors)
        .size(50)
        .adjust("stack");
      this.charObj.interaction("active-region");

      // 复写 图例筛选 交互。
      // 1、点击图例名称 进行 unchecked 状态的切换
      // 2、点击图例 marker，进行 checked 状态的切换（进行聚焦）
      // 3、双击 重置状态
      this.charObj.interaction("legend-filter", {
        start: [
          {
            trigger: "legend-item-name:click",
            action: ["list-unchecked:toggle", "data-filter:filter"],
          },
          {
            trigger: "legend-item-marker:click",
            action: ["list-checked:checked", "data-filter:filter"],
          },
        ],
        end: [
          {
            trigger: "legend-item-marker:dblclick",
            action: ["list-checked:reset", "data-filter:filter"],
          },
        ],
      });
    },

    //树依赖方法
    //树   展开节点的时候触发
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },

    //当点击选择的时候
    onSelect(selectedKeys) {
      this.treeSelectedKeys = selectedKeys;
      /*console.log(this.treeSelectedKeys)
      if (this.windDirectionMeterIds.indexOf(this.treeSelectedKeys[0]) > -1) {
          this.charObj.destroy();
          this.$nextTick(() => {
              this.windDirectionCharInit();
          });
      }else {
          this.charObj.destroy();
          this.charInit()
      }*/
    },

    onChange(e) {
      const value = e.target.value;
      if (value === "") {
        this.expandedKeys = [];
        this.searchValue = "";
      } else {
        const expandedKeys = this.titleData
          .map((item) => {
            if (item.title.indexOf(value) > -1) {
              return this.getParentKey(item.title, this.measurePointData);
            }
            return null;
          })
          .filter((item, i, self) => item && self.indexOf(item) === i);
        Object.assign(this, {
          expandedKeys,
          searchValue: value,
          autoExpandParent: true,
        });
      }
    },

    //树 获取父id 去展开
    getParentKey(title, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          //注意
          if (node.children.some((item) => item.name === title)) {
            //注意
            parentKey = node.code;
          } else if (this.getParentKey(title, node.children)) {
            parentKey = this.getParentKey(title, node.children);
          }
        }
      }
      return parentKey;
    },

    //获取树的所有节点值
    getTitleData(data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        //注意
        const title = node.name;
        this.titleData.push({
          title: title,
        });
        if (node.children) {
          this.getTitleData(node.children);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
/*滚动条整体样式*/
.scroll_bar::-webkit-scrollbar {
  height: 5px;
}

.scroll_bar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  background-color: rgba(143, 143, 143, 0.8);
}

.scroll_bar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
.main{
  height: 100%;
  overflow-y: auto;
}
.left-sty{
  height: 100%;
  overflow-y: auto;
}
.card-left-sty{
  height: 100%;
  overflow-y: auto;
}
</style>
